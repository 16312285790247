<template>
  <div id="app">
    <!-- <div v-html="statistics"></div> -->
    <!-- 顶部图片 -->
    <div class="thePicture">
      <div class="top-logo" v-if="!pdfHidden">
        <div>{{titles}}</div>
        <div>快估结果报告</div>
      </div>
      <!-- <img
        v-if="!pdfHidden"
        style="width: 100%; height: 100%"
        src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/quick_estimate_result_20210705.png"
        alt
      />-->

      <van-swipe v-if="pdfHidden" class="my-swipe" indicator-color="white">
        <van-swipe-item v-for="(item, index) in interfaceInfo.urlList" :key="index">
          <img style="width: 100%; height: 100%" :src="item" alt />
          <!-- {{ item }} -->
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- title -->
    <div class="theTitleView">{{ interfaceInfo.vehicleName }}</div>
    <!-- money -->
    <div class="theMoneyView">
      <div>
        <div class="title">
          估值参考价
          <van-icon size="16px" color="#013667" name="question-o" />
        </div>
        <div class="money">{{ interfaceInfo.price }}</div>
      </div>
    </div>
    <!-- 基本信息 -->
    <div class="informationView">
      <div class="informationViewTop">
        <div class="li">
          <div class="informationViewTopCity">{{ area }}</div>
          <div class="informationViewTopText">{{ provinces }}{{ city }}</div>
        </div>
        <div class="li">
          <div class="informationViewTopCity">{{ interfaceInfo.mileage }}</div>
          <div class="informationViewTopText">表征里程(km)</div>
        </div>
        <div class="li">
          <div class="informationViewTopCity">{{ aNew }}</div>
          <div class="informationViewTopText">年限成新率</div>
        </div>
      </div>

      <div
        class="informationViewContent"
        :class="[first_click ? 'show' : 'hide', state ? 'open' : 'close']"
      >
        <div class="info">
          <div class="infoView">
            <div class="flex">
              <div>生</div>
              <div>产</div>
              <div>年</div>
              <div>份</div>
            </div>
            <div class="text">{{ interfaceInfo.birth_day }}</div>
          </div>
          <div class="infoView">
            <div class="flex">
              <div>注</div>
              <div>册</div>
              <div>日</div>
              <div>期</div>
            </div>
            <div class="text">{{ interfaceInfo.RegisterDate }}</div>
          </div>
        </div>
        <div class="info">
          <div class="infoView">
            <div class="flex">
              <div>车</div>
              <div>龄</div>
            </div>
            <div class="text">{{ CarOld }}</div>
          </div>
          <div class="infoView">
            <div class="flex">
              <div>使</div>
              <div>用</div>
              <div>性</div>
              <div>质</div>
            </div>
            <div class="text">{{ interfaceInfo.UseCharacter }}</div>
          </div>
        </div>
        <div class="info">
          <div class="infoView">
            <div class="flex">
              <div>车</div>
              <div>辆</div>
              <div>型</div>
              <div>号</div>
            </div>
            <div class="text">{{ interfaceInfo.vehicleModel }}</div>
          </div>
        </div>
        <div class="info">
          <div class="infoView">
            <div class="flex">
              <div>车</div>
              <div>架</div>
              <div>号</div>
            </div>
            <div class="text">{{ interfaceInfo.vin }}</div>
          </div>
        </div>
        <div class="info">
          <div class="infoView">
            <div class="flex">
              <div>发</div>
              <div>动</div>
              <div>机</div>
              <div>型</div>
              <div>号</div>
            </div>
            <div class="text">{{ interfaceInfo.engine_model }}</div>
          </div>
        </div>
        <div class="info" style="border: none">
          <div class="infoView">
            <div class="flex">
              <div>发</div>
              <div>动</div>
              <div>机</div>
              <div>号</div>
              <div>码</div>
            </div>
            <div class="text">{{ interfaceInfo.EngineNo }}</div>
          </div>
        </div>
      </div>
      <div v-show="!state" class="informationViewBottom" @click="toggle">展开基本信息</div>
      <div v-show="state" class="informationViewBottom" @click="toggle">收起基本信息</div>
    </div>
    <!-- 参考价值 -->
    <div class="theValueView">
      <div class="title">特殊减值后的参考价值</div>
      <div class="info">
        <div class="infoView">
          <div class="infoViewTitle" v-if="transfer">可过户</div>
          <div class="infoViewTitle" style="color: #fe4000" v-if="!transfer">不可过户</div>
          <!-- <div class="infoViewTitle">可过户</div> -->
          <div class="infoViewText">{{ specialImpair.unTransferReason }}</div>
        </div>
        <div class="infoView">
          <div class="infoViewTitle" style="color: #333333">{{ specialImpair.residualPrice }}</div>
          <div v-if="transfer" class="infoViewText">参考价值(万元)</div>
          <div v-if="!transfer" class="infoViewText">残值(万元)</div>
        </div>
      </div>
    </div>
    <!-- special 特殊减值 -->
    <div class="theSpecialView">
      <div class="title">特殊减值项</div>
      <div class="theSpecialViewFlex">
        <div class="info">
          <div class="theSpecialViewTitle">
            <div>过</div>
            <div>户</div>
            <div>交</div>
            <div>易</div>
            <div>税(元)</div>
          </div>
          <div class="theSpecialViewText">{{ specialImpair.tax }}</div>
        </div>
        <div class="info">
          <div class="theSpecialViewTitle">
            <div>过</div>
            <div>户</div>
            <div>手</div>
            <div>续</div>
            <div>费(元)</div>
          </div>
          <div class="theSpecialViewText">{{ specialImpair.serviceCharge }}</div>
        </div>
      </div>
      <div class="theSpecialViewFlex">
        <div class="info">
          <div class="theSpecialViewTitle">
            <div>停</div>
            <div>车</div>
            <div>费(元)</div>
          </div>
          <div class="theSpecialViewText">{{ specialImpair.parkingFee }}</div>
        </div>
        <div class="info">
          <div class="theSpecialViewTitle">
            <div>代</div>
            <div>驾</div>
            <div>费(元)</div>
          </div>
          <div class="theSpecialViewText">{{ specialImpair.drivingFee }}</div>
        </div>
      </div>
      <div class="theSpecialViewFlex">
        <div class="info">
          <div class="theSpecialViewTitle">
            <div>违</div>
            <div>章</div>
            <div>罚</div>
            <div>款(元)</div>
          </div>
          <div class="theSpecialViewText">{{ specialImpair.violation }}</div>
        </div>
      </div>
      <div class="theSpecialViewBottom">
        <div class="title">合计(元)</div>
        <div class="text">{{ specialImpair.total }}</div>
      </div>
    </div>
    <!--  condition 车况描述 -->
    <div class="theConditionView">
      <div class="title">车况描述</div>

      <div class="theConditionViewFlex">
        <div class="info">
          <img
            style="width: 18px; height: 18px"
            src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/exterior_interior_20210804.png"
            alt
          />
          <div class="theConditionTitle">外观及内饰</div>
        </div>
        <div class="title">{{ interfaceInfo.cabScore }}</div>
      </div>
      <div class="theConditionViewFlex">
        <div class="info">
          <img
            style="width: 18px; height: 18px"
            src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/engine_20210804.png"
            alt
          />
          <div class="theConditionTitle">发动机</div>
        </div>
        <div class="title">{{ interfaceInfo.engineScore }}</div>
      </div>
      <div class="theConditionViewFlex">
        <div class="info">
          <img
            style="width: 18px; height: 18px"
            src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/chassis_20210804.png"
            alt
          />
          <div class="theConditionTitle">底盘</div>
        </div>
        <div class="title">{{ interfaceInfo.chassisScore }}</div>
      </div>
      <div class="theConditionViewFlex">
        <div class="info">
          <img
            style="width: 18px; height: 18px"
            src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/skeleton_20210804.png"
            alt
          />
          <div class="theConditionTitle">骨架</div>
        </div>
        <div class="title">{{ interfaceInfo.skeletonScore }}</div>
      </div>
    </div>
    <!-- eCharts -->
    <div class="theEChartsView">
      <div class="title">未来价格趋势</div>
      <div class="ECartsView" id="main"></div>
    </div>
    <!--销量数据  -->
    <div class="theSalesDataView">
      <div class="title">
        同品牌同类型同动力历史销量数据
        <span class="carNumber">单位：辆</span>
      </div>
      <div class="info" v-if="isArr">
        <div
          class="theSalesDataViewLi"
          v-for="(item, index) in interfaceInfo.brandOwnerShip"
          :key="index"
          v-show="index <= 3"
        >
          <div class="title" v-if="index == 0">国三</div>
          <div class="title" v-if="index == 1">国四</div>
          <div class="title" v-if="index == 2">国五</div>
          <div class="title" v-if="index == 3">国六</div>
          <div v-if="index <= 3" class="number">{{ item }}</div>
        </div>
      </div>
      <div class="echartsList" v-if="!isArr">
        <div class="bar" v-if="interfaceInfo.brandOwnerShip.type == 'bar'">
          <div class="ECartsView" id="carEcharts"></div>
        </div>
        <div class="line" v-if="interfaceInfo.brandOwnerShip.type == 'line'">
          <div class="ECartsView" id="car_echarts"></div>
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="theTableView">
      <div class="title">同类车辆近期成交价格</div>
      <div class="tableViewFlex" style="margin-top: 6px; background: rgba(1, 54, 103, 0.71)">
        <div class="tableViewTitle">品牌</div>
        <div class="tableViewTitle">成交价(万元)</div>
        <div class="tableViewTitle">车龄</div>
        <div class="tableViewTitle">里程(km)</div>
      </div>
      <!-- 循环 -->
      <div
        v-for="(item, index) in interfaceInfo.regionTrade"
        :key="index"
        class="tableViewFlex"
        :class="[index % 2 == 0 ? 'evenNumber' : '']"
      >
        <!-- {{ item }} -->
        <div class="tableViewTitleLi">{{ item.brand }}</div>
        <div class="tableViewTitleLi">{{ item.sale_price }}</div>
        <div class="tableViewTitleLi">{{ item.serviceLife }}</div>
        <div class="tableViewTitleLi">{{ item.mileage }}</div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <!-- <div class="theButtonView">
      <button class="sendEmail" @click="accessReportHidden = true">
        获取报告
      </button>
      <button class="sendEmail">分享</button>
    </div>-->
    <div class="theButtonView">
      <!-- v-clipboard:copy="value"
        v-clipboard:success="getAdd"
      v-clipboard:error="onError"-->
      <button class="sendEmail pdfLink" @click="getAdd">获取报告</button>
    </div>
    <!-- 获取报告的弹框  -->
    <van-popup round v-model="accessReportHidden" position="bottom" :style="{ height: '28%' }">
      <div class="theSendEmailView">
        <div class="title">获取报告</div>
        <div class="content">
          <div
            class="info"
            v-clipboard:copy="value"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <img
              style="width: 38px; height: 38px"
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/report_download_20220118.png"
              alt
            />
            <div class="text">本地下载</div>
          </div>
          <div class="info" @click="sendEmailClick">
            <img
              style="width: 38px; height: 38px"
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/report_send_email_20220118.png"
              alt
            />
            <div class="text">发送邮箱</div>
          </div>
        </div>
        <div class="button" @click="accessReportHidden = false">取消</div>
      </div>
    </van-popup>
    <!-- 发送邮箱的弹框 -->
    <van-dialog
      v-model="sendEmailHidden"
      show-cancel-button
      @cancel="sendEmailCancel"
      @confirm="sendEmailConfirm"
      confirm-button-color="#013667"
      cancel-button-color="#999999"
      :before-close="onBeforeClose"
    >
      <div class="EmailContent">
        <!-- title -->
        <div class="title">发送邮箱</div>
        <div class="text">电子邮箱：</div>
        <input type="text" v-model="listNum.email" placeholder="请输入您的邮箱，用于向您发送评估报告" />
        <div class="prompt">报告预计5分钟后，发送至您的邮箱</div>
      </div>
    </van-dialog>
  </div>
</template>
<script type="text/javascript" src='https://c.cnzz.com/core.php?web_id=1281102051&t=z' charset="utf-8">
</script>
<script>
// import * as echarts from "echarts";
import Clipboard from "clipboard";

import echarts from "echarts";
import { Toast, Dialog } from "vant";
//
import { newCreatePdf, consumerQuickValuation } from "@/utils/http.js"; //路径注意
// import {  } from "vant";

export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      isArr: false,
      pdfLink: "",
      titles: "",
      statistics: "",
      // 判断顶部
      pdfHidden: false,
      // 动画
      state: false,
      first_click: false,
      // -- 省市区
      provinces: "",
      city: "",
      area: "",
      // 成新
      aNew: "",
      // 车龄
      CarOld: "",
      specialImpair: {},
      transfer: null,
      // --

      // 获取报告
      accessReportHidden: false, //获取报告的弹框
      // 发送邮箱
      sendEmailHidden: false, //发送邮箱的弹框
      // 输入邮箱的对象
      listNum: {
        email: "",
      },

      value: "11111111111111111111",
      interfaceInfo: {}, //渲染数据
      params: "",
      interfaceInfo: {
        brandOwnerShip: {
          type: "",
        },
      },
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    formatNum(strNum) {
      if (strNum.length <= 3) {
        return strNum;
      }
      if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
        return strNum;
      }
      var a = RegExp.$1,
        b = RegExp.$2,
        c = RegExp.$3;
      var re = new RegExp();
      re.compile("(\\d)(\\d{3})(,|$)");
      while (re.test(b)) {
        b = b.replace(re, "$1,$2$3");
      }
      return a + "" + b + "" + c;
    },
    // 柱状图
    carEcharts() {
      let that = this;
      let carEcharts = echarts.init(document.getElementById("carEcharts"));

      let list = this.interfaceInfo.brandOwnerShip.categories;
      let data = this.interfaceInfo.brandOwnerShip.sales;
      let option = {
        grid: {
          left: "4%",
          right: "14%",
          bottom: "-10%",
          top: "2%",
          containLabel: true,
        },
        tooltip: {
          trigger: "lines",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
          data: list,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            // formatter: '{value}',
            textStyle: {
              //改变刻度字体样式
              color: "#356FC8",
            },
          },
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "right",
              color: "#356FC8",
              formatter: function (p) {
                return that.formatNum(p.value);
              },
            },
            data,
            barWidth: "50%",
            itemStyle: {
              barBorderRadius: [2, 2, 0, 0], //柱体圆角
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  //代表渐变色从正上方开始
                  offset: 1,
                  color: "#ffffff",
                },
                {
                  offset: 0, //指100%处的颜色
                  color: "#4F96FF",
                },
              ]),
            },
            animationEasing: "quadraticOut",
          },
        ],
      };
      carEcharts.setOption(option);
      console.log(123123);
    },
    // 折线图
    car_echarts() {
      let that = this;
      let carEcharts = echarts.init(document.getElementById("car_echarts"));
      let list = this.interfaceInfo.brandOwnerShip.categories;
      let data = this.interfaceInfo.brandOwnerShip.sales;
      var option = {
        grid: {
          left: "-8%",
          right: "6%",
          bottom: "0",
          top: "4%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: list,
          axisLine: {
            lineStyle: {
              width: 1,
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data,
            type: "line",
            symbol: "circle",
            areaStyle: {},
            itemStyle: {
              normal: {
                color: "#356FC8",
                fontSize: 20,
                lineStyle: {
                  type: "dashed",
                  color: "#356FC8",
                  width: 1,
                },
                areaStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#4F96FF", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#fff", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },
            },
            label: {
              show: true,
              color: "#356FC8",
              position: "top",
              formatter: function (p) {
                return that.formatNum(p.value);
              },
            },
            animationEasing: "quadraticOut",
          },
        ],
      };
      carEcharts.setOption(option);
    },
    // 动画
    toggle: function () {
      var that = this;
      var list_state = this.state,
        first_state = this.first_click;
      if (!first_state) {
        this.first_click = true;
      }
      if (list_state) {
        this.state = false;
        setTimeout(function () {
          that.first_click = false;
        }, 100);
      } else {
        this.state = true;
      }
    },
    parseData(data, date) {
      let obj = {
        data: data,
        date: date,
      };
      var filter = [];
      obj.data.unshift(data[0]);
      obj.data.push(data[data.length - 1]);
      obj.date.unshift(date[0]);
      obj.date.push(date[date.length - 1]);
      obj.date.forEach(function (item, index) {
        var row = {
          textStyle: {},
        };
        row.value = item;
        if (index === 0 || index === obj.date.length - 1) {
          row.textStyle = { color: "#fff" };
        }
        if (index === obj.date.length - 2) {
          row.textStyle = { color: "#333" };
        }
        filter.push(row);
      });
      obj.date = filter;
      return obj;
    },
    // eCharts
    echartsInit() {
      var a = this.parseData(
        this.interfaceInfo.futurePrice,
        this.interfaceInfo.futureDate
      );

      echarts.init(document.getElementById("main")).setOption({
        xAxis: {
          // type: 'value',
          data: a.date,
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(98,168,234,.3)",
              type: "dashed",
            },
          },
          axisLabel: {
            color: "#000000",
            fontSize: 10,
          },
          axisLine: {
            onZeroAxisIndex: 10,
            show: true,
            symbolOffset: [20, 20],
            lineStyle: {
              type: "dotted",
              color: "#fff",
            },
          },
          axisPointer: {
            label: {
              backgroundColor: "rgba(0,0,0,.8)",
            },
          },
        },
        grid: {
          left: "-9%",
          right: "-2%",
          bottom: "8rpx",
          top: 50,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          padding: [5, 10],
        },
        yAxis: {
          show: false,
          axisPointer: {
            show: false,
          },
          axisTick: {
            show: false,
          },

          splitLine: {
            show: false,
          },
        },
        series: [
          {
            label: {
              show: true,
              color: "#356FC8",
              fontSize: 10,
              position: "top",
            },
            // name: '购置费用',
            symbol: "circle",
            symbolSize: 5,
            smooth: true,
            type: "line",
            itemStyle: {
              normal: {
                color: "#356FC8",
                fontSize: 20,
                lineStyle: {
                  type: "dashed",
                  color: "#356FC8",
                  width: 1,
                },
                areaStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#4F96FF", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#fff", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },
            },
            data: a.data,
            animationDuration: 2800,
            animationEasing: "quadraticOut",
            bigImg: null,
            isShow: false, // 是否拿到图片
            inputShowed: false,
            inputVal: "",
          },
        ],
      });
    },
    // 发送邮箱的按钮
    sendEmailClick() {
      this.accessReportHidden = false;
      this.sendEmailHidden = true;
    },
    // 发送邮箱的取消按钮
    sendEmailCancel() {
      this.accessReportHidden = true;
      this.sendEmailHidden = false;
    },
    // 发送邮箱点击确定按钮
    sendEmailConfirm() {
      let listNum = this.listNum;
      if (listNum.email == "") {
        return Toast("邮箱为空");
      }
      var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(listNum.email)) {
        return Toast("请输入有效的邮箱");
      }
      // 邮箱发起请求
    },
    // 阻止弹出层点击确定自动关闭
    onBeforeClose(active, done) {
      if (active === "confirm") {
        return done(false);
      } else {
        return done();
      }
    },
    // 点击本地下载
    onCopy(e) {
      this.accessReportHidden = false;
      Toast("复制成功");
    },
    tips(data) {
      if (data) {
        if (data == 1000) {
          Dialog.alert({
            title: "提示信息",
            message: "验证失败,请返回上级页面重新验证",
          }).then(() => {});
          return false;
        }
        if (data == 1001) {
          Dialog.alert({
            title: "提示信息",
            message: "appKey错误",
          }).then(() => {});
          return false;
        }
        if (data == 1002) {
          Dialog.alert({
            title: "提示信息",
            message: "暂无权限",
          }).then(() => {});
          return false;
        }
        if (data == 1003) {
          Dialog.alert({
            title: "提示信息",
            message: "余额不足",
          }).then(() => {});
          return false;
        }
        if (data == 1004) {
          Dialog.alert({
            title: "提示信息",
            message: "签名已过期",
          }).then(() => {});
          return false;
        }
      }
      return true;
    },
    onError(e) {
      Toast("复制失败");
    },
    clickPdfLinkError(e) {
      console.log(e, "error");
    },
    clickPdfLink(e) {
      console.log(e, "success");
    },
    async getAdd() {
      var obj = {
        recordId: this.interfaceInfo.recordId,
        appKey: this.$route.query.appKey,
        sign: this.$route.query.sign,
        randomStr: this.$route.query.randomStr,
        expireTime: this.$route.query.expireTime,
        orderNo: this.$route.query.orderNo,
      };
      // var obj = {
      //   recordId: 453,
      //   appKey: "cada417a94357a9521ebf1b7",
      //   sign: "5264425ab8fd7fd30e627fcb0072851b",
      //   randomStr: "12345678",
      //   expireTime: "1652836339000",
      //   orderNo: "CVBD0011658238010156409560",
      // };
      const { data: res } = await newCreatePdf(obj);
      if (res.failureReason == 3) {
        Toast("报告生成中，请稍后");
        return;
      }
      if (res.failureReason == 1000) {
        Toast("参数错误");
        return;
      }
      // window.location.href = res.content.pdfUrl;
      // Toast("请稍后");
      // this.copyText(res.content.pdfUrl);
      // this.pdfLink = res.content.pdfUrl;
      this.$copyText(res.content.pdfUrl)
        .then((res) => {
          Toast("复制成功,请在浏览器打开");
        })
        .catch((err) => {
          Toast("复制失败,请重试");
        });
    },
    async getList() {
      let valuationInfo = {
        appKey: this.$route.query.appKey,
        sign: this.$route.query.sign,
        randomStr: this.$route.query.randomStr,
        expireTime: this.$route.query.expireTime,
        orderNo: this.$route.query.orderNo,
      };
      const { data: res } = await consumerQuickValuation(valuationInfo);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }
      if (res.code == 500) {
        Toast("暂无车辆信息");
        return;
      }
      if (res.code == 100) {
        Toast(res.message);
        return;
      }
      if (res.code == 700) {
        Toast("该功能次数已耗尽");
        return;
      }
      if (res.code == 104) {
        Toast("没有权限");
        return;
      }
      if (res.code == 703) {
        Toast("亲，账户余额不足以支付本次费用了，请联系管理员充值");
        return;
      }
      if (res.data.failure) {
        if (res.data.result.failure == "valuationError") {
          Toast("暂无估价结果");
          return;
        }
        if (res.data.result.failure == "ocrError") {
          Toast("ocr识别异常");
          return;
        }
        if (res.data.result.failure == "notExist") {
          Toast("正在收录该车型，后期将展示结果");
          return;
        }
        if (res.data.result.failure == "paramError") {
          Toast("请核对输入内容，输入内容有误");
          return;
        }
        if (res.data.result.failure == "noPrice") {
          Toast("正在收录该车型，后期将展示结果。");
          return;
        }
        if (res.data.result.failure == "yearsExceeded") {
          Toast("你输入的里程超限，无法估价");
          return;
        }
      }
      this.titles = res.data.title;
      this.interfaceInfo = res.data.result;
      var interfaceInfo = res.data.result;
      // this.interfaceInfo = res.data;
      // var interfaceInfo = res.data;
      this.specialImpair = interfaceInfo.specialImpair;
      this.transfer = interfaceInfo.specialImpair.transfer;
      // 判断 有没有图片
      if ("urlList" in interfaceInfo) {
        this.pdfHidden = true;
      } else {
        this.pdfHidden = false;
      }

      // 省市区
      this.provinces = interfaceInfo.locale.trim().split(" ")[0];
      this.city = interfaceInfo.locale.trim().split(" ")[1];
      this.area = interfaceInfo.locale.trim().split(" ")[2];
      // 成新
      this.aNew = interfaceInfo.annualDepreciationRate.trim().split(" ")[1];
      // 车龄
      this.CarOld = interfaceInfo.carAge.trim().split(" ")[1];
      this.interfaceInfo = interfaceInfo;
      this.echartsInit();
      if (Array.isArray(interfaceInfo.brandOwnerShip)) {
        this.isArr = true;
      } else {
        this.isArr = false;
        if (interfaceInfo.brandOwnerShip.type == "bar") {
          setTimeout(() => {
            this.carEcharts();
          }, 1000);
        } else {
          setTimeout(() => {
            this.car_echarts();
          }, 1000);
        }
      }
    },
    copyText(text) {
      // 数字没有 .length 不能执行selectText 需要转化成字符串
      const textString = text.toString();
      let input = document.querySelector("#copy-input");
      if (!input) {
        input = document.createElement("input");
        input.id = "copy-input";
        input.readOnly = "readOnly"; // 防止ios聚焦触发键盘事件
        input.style.position = "absolute";
        input.style.left = "-1000px";
        input.style.zIndex = "-1000";
        document.body.appendChild(input);
      }
      input.value = textString;
      // ios必须先选中文字且不支持 input.select();

      console.log(document.execCommand("copy"), "execCommand");
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        Toast("复制成功,请在浏览器打开");
      }
      this.selectText(input, 0, textString.length);
      input.blur();
      // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
      // 选择文本。createTextRange(setSelectionRange)是input方法
    },
    selectText(textbox, startIndex, stopIndex) {
      if (textbox.createTextRange) {
        //ie
        const range = textbox.createTextRange();
        range.collapse(true);
        range.moveStart("character", startIndex); //起始光标
        range.moveEnd("character", stopIndex - startIndex); //结束光标
        range.select(); //不兼容苹果
      } else {
        textbox.setSelectionRange(startIndex, stopIndex);
        textbox.focus();
        Toast("复制成功,请在浏览器打开");
      }
    },
  },

  created() {
    this.params = this.$route.query;
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://s4.cnzz.com/z_stat.php?id=1281102051&web_id=1281102051";
    document.body.appendChild(s);
  },
  beforeMount() {},
  mounted() {
    let str = unescape(
      "%3Cspan id='cnzz_stat_icon_1281102051'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1281102051' type='text/javascript'%3E%3C/script%3E"
    );
    this.statistics = str;
    // this.carEcharts();
    // this.car_echarts();
    this.getList();
  },
};
</script> 

<style scoped>
#app {
  width: 100%;
  min-height: 100vh;
  font-family: PingFang-SC-Bold, PingFang-SC;
  overflow: hidden;
  background: #f2f2f2;
}
/* 顶部图片 */
.thePicture {
  width: 100%;
  height: 188px;
}
.my-swipe {
  width: 100%;
  height: 100%;
}
/* title */
.theTitleView {
  width: 100%;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  box-sizing: border-box;
  padding-left: 14px;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
/* money */
.theMoneyView {
  width: calc(100% - 20px);
  height: 74px;
  background: #ffffff;
  border-radius: 5px;
  margin: 10px auto 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 0;
}
.theMoneyView .title {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.theMoneyView .money {
  font-size: 20px;
  font-weight: bold;
  color: #fe4000;
  text-align: center;
  margin-top: 4px;
}
.informationView {
  width: calc(100% - 20px);
  /* height: 330px; */
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  margin: 10px auto 0;
}
.informationViewTop {
  width: 100%;
  height: 66px;
  background: #f3f7fa;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.informationViewTop > .li {
  width: 100px;
  text-align: center;
}
.informationViewTopCity {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.informationViewTopText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-top: 4px;
}
.informationViewContent {
  width: 100%;
  height: 226px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px;
}
.informationViewContent > .info {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #dddddd;
  display: flex;
}
.informationViewContent .infoView {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
}
.informationViewContent .flex {
  display: flex;
  width: 70px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  align-items: center;
  justify-content: space-between;
}
.informationViewContent .text {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
.informationViewBottom {
  width: 100%;
  height: 30px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 30px;
  border-top: 1px solid #e8e8e8;
}
/* 动画 */
@keyframes slidedown {
  from {
    height: 0;
  }
  to {
    height: 226px;
  }
}

@keyframes slideup {
  from {
    height: 226px;
  }
  to {
    height: 0;
  }
}

.open {
  animation: slidedown 0.3s;
}

.close {
  animation: slideup 0.3s;
}

.hide {
  display: none;
}

.show {
  display: block;
}
.theValueView {
  width: calc(100% - 20px);
  height: 106px;
  background: #ffffff;
  border-radius: 5px;
  margin: 10px auto 0;
}
.theValueView > .title {
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 10px;
}
.theValueView > .info {
  width: 100%;
  height: 66px;
  background: #f3f7fa;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.theValueView .infoView {
  width: 104px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.infoViewTitle {
  font-size: 16px;
  font-weight: bold;
  color: #1ada23;
  text-align: center;
}
.infoViewText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-top: 4px;
}
/* 特殊 */
.theSpecialView {
  width: calc(100% - 20px);
  height: 196px;
  background: #ffffff;
  border-radius: 5px;
  margin: 10px auto 0;
}
.theSpecialView > .title {
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 10px;
}
.theSpecialViewFlex {
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}
.theSpecialViewFlex > .info {
  flex: 1;
  display: flex;
}
.theSpecialViewTitle {
  width: 94px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.theSpecialViewText {
  width: 60px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
.theSpecialViewBottom {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}
.theSpecialViewBottom > .title {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.theSpecialViewBottom > .text {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
/* 车况描述 */
.theConditionView {
  width: calc(100% - 20px);
  box-sizing: border-box;
  padding: 0 10px;
  height: 194px;
  background: #ffffff;
  border-radius: 5px;
  margin: 10px auto 0;
}
.theConditionView > .title {
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 40px;
  box-sizing: border-box;
}
.theConditionViewFlex {
  width: 100%;
  height: 38px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
}
.theConditionViewFlex > .info {
  width: 100px;
  display: flex;
}
.theConditionTitle {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 6px;
}
.theConditionViewFlex > .title {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  flex: 1;
  margin-left: 50px;
}
.theEChartsView {
  width: calc(100% -20px);
  margin: 10px auto 0;
  height: 250px;
  background: #ffffff;
}
.theEChartsView > .title {
  box-sizing: border-box;
  padding: 10px 0 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
}
.ECartsView {
  width: 100%;
  height: 166px;
}
.theSalesDataView {
  width: calc(100% - 20px);
  min-height: 108px;
  margin: 10px auto 0;
  box-sizing: border-box;
  padding: 10px;
  background: #ffffff;
  border-radius: 6px;
}
.theSalesDataView > .title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.theSalesDataView > .info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  margin-top: 10px;
}
.theSalesDataViewLi {
  width: 23%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.theSalesDataViewLi > .title {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
}
.theSalesDataViewLi > .number {
  font-size: 18px;
  font-weight: bold;
  color: #f7b500;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  margin-top: 4px;
}
/* table */
.theTableView {
  width: calc(100% - 20px);
  height: 230px;
  background: #ffffff;
  border-radius: 6px;
  margin: 10px auto 0;
  box-sizing: border-box;
  padding: 10px 0;
}
.theTableView > .title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  box-sizing: border-box;
  padding-left: 10px;
}
.tableViewFlex {
  display: flex;
  width: 100%;
  height: 28px;
  justify-content: space-around;
  align-items: center;
}
.tableViewTitle {
  min-width: 76px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}
/* .baseNumber {
} */
.evenNumber {
  background: #f3f7fa;
}
.tableViewTitleLi {
  width: 76px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
.theButtonView {
  width: calc(100% - 20px);
  height: 44px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sendEmail {
  /* width: 46%; */
  width: 100%;
  height: 100%;
  border: none;
  background: #013667;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.theSendEmailView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 20px;
}
.theSendEmailView > .title {
  font-size: 17px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.theSendEmailView > .content {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.theSendEmailView > .button {
  width: 100%;
  height: 50px;
  border-top: 1px solid #efefef;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.theSendEmailView .info {
  flex: 1;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.theSendEmailView .text {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  margin-top: 8px;
}
/* 发送邮箱 */
.EmailContent {
  box-sizing: border-box;
  padding: 20px;
}
.EmailContent > .title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.EmailContent > .text {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  margin-top: 16px;
}
.EmailContent > input {
  margin-top: 10px;
  width: 100%;
  border: none;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.EmailContent > .prompt {
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
  margin-top: 10px;
}
input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.top-logo {
  background-image: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/quick_estimate_h5_result_20220427.png");
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
}
.top-logo > div:nth-of-type(1) {
  padding-top: 20px;
}
.top-logo > div:nth-of-type(2) {
  padding-top: 6px;
}

.carNumber {
  float: right;
  color: #ccc;
  font-size: 12px;
  margin-top: 4px;
}
.echartsList {
  margin-top: 10px;
}
</style>
